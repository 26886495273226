export default () => ({
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");
    new Swiper(this.$refs.sliderH, {
      loop: true,
      slidesPerView: "auto",
      spaceBetween: 30,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      breakpoints: {
        // when window width is >= 320px
        770: {
          spaceBetween: 30
        },
        320: {
          spaceBetween: 0
        },
      },
    });
  },
});


import Alpine from "alpinejs";
import collapse from '@alpinejs/collapse';

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import Accordion from "./Accordion";
Alpine.data("Accordion", Accordion);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import OpenModal from "./OpenModal"
Alpine.data("OpenModal", OpenModal)

import HorizontalCasesSwiper from "./HorizontalCasesSwiper"
Alpine.data("HorizontalCasesSwiper", HorizontalCasesSwiper)

import VerticalCasesSwiper from "./VerticalCasesSwiper"
Alpine.data("VerticalCasesSwiper", VerticalCasesSwiper)

Alpine.store("mainMenu", {
    open: false,
});

Alpine.plugin(collapse)
Alpine.start();

window.addEventListener("scroll", function () {
    var scroll = window.pageYOffset || document.documentElement.scrollTop;
    var masthead = document.querySelector("header");
    if (scroll >= 50) {
        masthead.classList.add("header--scrolled");
    } else {
        masthead.classList.remove("header--scrolled");
    }
});
export default () => ({
  async init() {
    const { default: Swiper } = await import("../libs/Swiper");
    new Swiper(this.$refs.sliderV, {
      direction: "vertical",
      loop: true,
      slidesPerView: 1,
      pagination: {
        el: this.$refs.pag,
        clickable: true,
      },
    });
  },
});
